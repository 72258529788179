body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#rkd-render-root .MuiPaper-root{
    background: #FFF !important;
}

#rkd-render-root .MuiAppBar-colorPrimary{
    background-color: #007bff !important;
}

.giftArrayBtn .btn-giftArray{
    border-color: #007bff;
}
.giftArrayBtn .btn-giftArray-contain{
    background-color: #007bff;
}

.giftArrayBtn .btn-outline-primary:hover{
    background-color: #007bff;
}

.giftArrayBtn .btn-giftArray-contain.btn-giftArray:focus{
    box-shadow: none;
}

.giftArrayBtn .other-amt-btn{
    padding-top: 8px;
    padding-bottom:8px;
}

.giftArrayBtn .other-amt-btn:hover{
    background-color: transparent;
}

/* Chrome, Safari, Edge, Opera */
.btn-giftArray input::-webkit-outer-spin-button,
.btn-giftArray input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.btn-giftArray input[type=number] {
  -moz-appearance: textfield;
}

.form-group label.required:after{
    content: '*';
    color: red;
} 

.d-none{
    display: none;
}

.image-button-payment-ui {
    border: none !important;
    background: none !important;
}

.image-button-payment-ui:focus {
    outline: none !important;
    box-shadow: none !important;
}

.image-button-payment-ui:active {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.paymentinfo-section .nav-item .btn {
    height: 45px;
    width: 180px;
    padding: 0px;
}

.paymentinfo-section .payment-failure, .giftArrayBtn.error-invalid-amount {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    margin-bottom: 10px;
    padding-top: 28px !important;
}

.image-button-payment-ui img {
    height: 45px;
    width: 180px;
}

.paymentinfo-section .ginput_card_security_code_icon {
    width: 32px;
    height: 23px;
    background-image: url(https://s3.amazonaws.com/public.charitable.one/assets/clientAssets/LAM/donation/gf-creditcard-icons.png);
    background-repeat: no-repeat;
    background-position: 0 -128px;
    position: relative;
    top: 38px;
    left: -6px;
    display: -moz-inline-stack;
    display: inline-block;
}